import React from "react";
import GoogleMapReact from "google-map-react";
import Logo from "../Logo/Logo";
import gsap from "gsap";
import "./Map.scss";

// 51.4749937,-0.0425353

// const AnyReactComponent = ({ text }) => <div>{text}</div>;

class Map extends React.Component {
  constructor(props) {
    super(props);
    this.mapOverlay = null;
  }
  componentDidMount() {
    var tl = gsap.timeline();
    // console.log(this.mapOverlay);
    tl.to(this.mapOverlay, { autoAlpha: 1, delay: 1, duration: 5 });
  }
  render() {
    const defaultProps = {
      center: {
        lat: 51.4749904,
        lng: -0.0403466,
      },
      zoom: 10,
    };

    const defaultOptions = {
      zoomControl: false,
      gestureHandling: "cooperative",
      fullscreenControl: false,
      styles: [
        {
          featureType: "all",
          elementType: "labels",
          stylers: [
            {
              visibility: "off",
            },
          ],
        },
        {
          featureType: "administrative",
          elementType: "labels",
          stylers: [
            {
              visibility: "off",
            },
          ],
        },
        {
          featureType: "administrative",
          elementType: "labels.text.fill",
          stylers: [
            {
              color: "#444444",
            },
            {
              visibility: "off",
            },
          ],
        },
        {
          featureType: "administrative.neighborhood",
          elementType: "labels",
          stylers: [
            {
              visibility: "off",
            },
          ],
        },
        {
          featureType: "landscape",
          elementType: "all",
          stylers: [
            {
              visibility: "on",
            },
            {
              color: "#1f2528",
            },
          ],
        },
        {
          featureType: "landscape",
          elementType: "labels",
          stylers: [
            {
              visibility: "off",
            },
          ],
        },
        {
          featureType: "poi",
          elementType: "all",
          stylers: [
            {
              visibility: "off",
            },
          ],
        },
        {
          featureType: "poi",
          elementType: "labels",
          stylers: [
            {
              visibility: "off",
            },
          ],
        },
        {
          featureType: "poi.park",
          elementType: "geometry",
          stylers: [
            {
              color: "#1b292d",
            },
            {
              visibility: "on",
            },
          ],
        },
        {
          featureType: "road",
          elementType: "all",
          stylers: [
            {
              saturation: -100,
            },
            {
              lightness: "28",
            },
          ],
        },
        {
          featureType: "road",
          elementType: "geometry.fill",
          stylers: [
            {
              visibility: "on",
            },
            {
              // color: "#0e6881"
              color: "#e7d5bf",
            },
            {
              weight: "0.3",
            },
          ],
        },
        {
          featureType: "road",
          elementType: "labels",
          stylers: [
            {
              visibility: "off",
            },
          ],
        },
        {
          featureType: "road.highway",
          elementType: "all",
          stylers: [
            {
              visibility: "simplified",
            },
          ],
        },
        {
          featureType: "road.highway",
          elementType: "labels",
          stylers: [
            {
              visibility: "off",
            },
          ],
        },
        {
          featureType: "road.arterial",
          elementType: "labels.icon",
          stylers: [
            {
              visibility: "off",
            },
          ],
        },
        {
          featureType: "transit",
          elementType: "all",
          stylers: [
            {
              visibility: "off",
            },
          ],
        },

        {
          featureType: "transit",
          elementType: "labels",
          stylers: [
            {
              visibility: "off",
            },
          ],
        },
        {
          featureType: "water",
          elementType: "all",
          stylers: [
            {
              color: "#ff7601",
            },
            {
              visibility: "on",
            },
          ],
        },
        {
          featureType: "water",
          elementType: "labels",
          stylers: [
            {
              visibility: "off",
            },
          ],
        },
      ],
    };
    return (
      <div
        ref={mapOverlay => (this.mapOverlay = mapOverlay)}
        className="map-wrap"
        style={{}}
      >
        <GoogleMapReact
          options={defaultOptions}
          bootstrapURLKeys={{ key: "AIzaSyCg9bUWWrt0lau1cUTaGv3RkA9mcB603Zc" }}
          defaultCenter={defaultProps.center}
          defaultZoom={defaultProps.zoom}
        >
          <Logo
            center
            // defaultCenter={defaultProps.center}
            lat={defaultProps.center.lat}
            lng={defaultProps.center.lng}
          />
        </GoogleMapReact>
      </div>
    );
  }
}
export default Map;
