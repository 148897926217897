import React, { useState } from "react";
import SEO from "../components/Seo/Seo";

// import { css } from "@emotion/core";
import "./styles/contact.scss";
import { useForm } from "react-hook-form";
import cx from "classnames";
// import ReCAPTCHA from "react-google-recaptcha";
import Map from "../components/Map/Map";
import axios from "axios";
import * as qs from "query-string";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default function Contact(props) {
  const { register, handleSubmit, errors, reset } = useForm({ mode: "onBlur" });
  const [hideSuccess, setSuccess] = useState(true);

  const onSubmit = (data, e) => {
    const axiosOptions = {
      url: "/",
      method: "post",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      data: qs.stringify({ "form-name": "Contact", ...data }),
    };
    console.log("here:", qs.stringify({ "form-name": "contact", ...data }));
    axios(axiosOptions)
      .then(response => {
        e.target.reset();
        setSuccess(false);
      })
      .catch(err => console.log("err:", err));
  };

  return (
    <div className="contact-page">
      <SEO title="Contact" />
      <h1 className="page-title">Contact</h1>
      <p className="page-info">
        I live and work in London.
        <br /> Want to hire me for a project? Get in touch via the form below!
      </p>
      <Map />
      {hideSuccess ? (
        <>
          <form
            method="post"
            onSubmit={handleSubmit(onSubmit)}
            name="Contact"
            action="/contact/"
            data-netlify="true"
            data-netlify-honeypot="bot-field"
          >
            <input type="hidden" name="form-name" value="Contact" />
            <label>
              Name
              <input
                type="text"
                placeholder="Your name"
                name="Name"
                className={cx({ warn: errors.Name })}
                ref={register({
                  required: { value: true, message: "Please enter your name" },
                  maxLength: {
                    value: 80,
                    message:
                      "Your name is too long, please use the short version of your name ;)",
                  },
                })}
              />
              {errors.Name && <span>{errors.Name.message}</span>}
            </label>
            <label>
              Email
              <input
                className={cx({ warn: errors.Email })}
                type="email"
                placeholder="eg. email42@gmail.com"
                name="Email"
                ref={register({
                  required: {
                    value: true,
                    message: "Please enter an email address",
                  },
                  pattern: {
                    value: /^\S+@\S+$/i,
                    message:
                      "This doesn't look like a real email address, try again",
                  },
                })}
              />
              {errors.Email && <span>{errors.Email.message}</span>}
            </label>

            <label>
              Message
              <textarea
                className={cx({ warn: errors.Message })}
                name="Message"
                placeholder="Hi Malcolm...."
                ref={register({
                  required: { value: true, message: "Your message?" },
                })}
              />
              {errors.Message && <span>{errors.Message.message}</span>}
            </label>
            <label className="hidden">
              Don’t fill this out if you're human: <input name="bot-field" />
            </label>

            <div className="button-wrap">
              <button className="btn" type="submit">
                <FontAwesomeIcon icon={["fas", "paper-plane"]} size="sm" />
                Submit
              </button>
              <button className="btn" type="reset" onClick={reset}>
                <FontAwesomeIcon icon={["fas", "trash"]} size="sm" />
                Clear
              </button>
            </div>
          </form>
        </>
      ) : (
        <div className="form-success">
          <h3>SUCCESS!</h3>
          <p>
            Thanks for getting in touch!
            <br />
            I'll get back to you as soon as I can.
          </p>
        </div>
      )}
    </div>
  );
}

// export default Contact;
